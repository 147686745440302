<template>
  <div class="loading-payment-check h-100">
    <div class="h-100 d-flex align-items-center justify-content-center">
        <div  class="w-100 text-center">
            <div>
                {{ status }}
            </div>
            <div 
                v-if="success_order_id"
                class="link"
                @click="goOrderDetail()"
                >
                こちらから詳細を確認できます。
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { Api } from "../../utils/http-common.js";
import { Urls } from "../../utils/urls";

export default {

  name: "external-payment-approval",
  data() {
    return {
      status: '処理中です。しばらくそのままでお待ちください。',
      success_order_id: null,
    };
  },
  async mounted() {
    const url = `${Urls.EXTERNAL_PAYMENT_APPORVAL}`;
    const method = "POST";
    const data = {
        token: this.$route.params.token,
        //log_data
        title: this.$route.meta.title,
        url: window.location.origin + this.$route.fullPath,
    }
    await Api.adminRequestServer({
            method,
            url,
            data: data,
        })
        .then( res => {
            this.status = res.data.message
            this.success_order_id = res.data.data.id ?? null;
        })
        .catch( () => {
            this.status = 'システムエラーが発生しました。管理者に連絡してください。';
        })
  },
  methods: {
    goOrderDetail(){
        const shopId = this.$route.params.shopId;
        if (shopId) {
            this.$router.push({
                name: 'EditOrder',
                params: {
                    id: this.success_order_id,
                    shopId: this.$route.params.shopId,
                },
            })
        } else {
            this.$router.push({
                name: 'EditOrder domain',
                params: {
                    id: this.success_order_id,
                },
            });            
        }
    },
  },
};
</script>
<style lang="scss">
.loading-payment-check {
    .link {
        color:#00F;
        cursor: pointer;
    }
}
</style>
